import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
//redirectUri: "https://commencementname.azurewebsites.net/",
const msalConfig = {
  auth: {
    clientId: "09443448-a1b4-43e7-bcfe-4034a1f4c4a1",
    authority:
      "https://login.microsoftonline.com/f6b6dd5b-f02f-441a-99a0-162ac5060bd2",
    redirectUri: "https://commencementname.azurewebsites.net/",
    postLogoutRedirectUri: "https://commencementname.azurewebsites.net/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};
const pca = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
