import * as React from "react";
import { Navbar, Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./header.css";

interface IHeaderProps {}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  return (
    <Navbar>
      <Container>
        <Navbar.Brand className="nav-bar">
          <div id="header-div" className="header-img">
            <Image
              fluid
              className="image"
              src="https://uwcommencement.blob.core.windows.net/commencement-images/GradSch_UW_White-01.png"
              alt="img"
            />
          </div>
          <h3 className="form-heading"> Commencement Form</h3>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
