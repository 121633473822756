import "./App.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { HomePage } from "./components/home";
import { LoginPage } from "./components/login";

const App = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <HomePage />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
