import React from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import "./additionalInformation.css";

interface IAdditionalInfoSectionProps {}

const AdditionalInfoSection: React.FunctionComponent<
  IAdditionalInfoSectionProps
> = (props) => {
  return (
    <>
      <Container>
        <Row>
          <Col className="mt-5 mb-5 col-16">
            <h4 className="additional-info-heading">
              FAQ/Additional resources
            </h4>
            {/* <Table bordered responsive variant="secondary">
              <thead>
                <tr>
                  <th>Core Concept</th>
                  <th>Type of resource</th>
                  <th>Question/Description</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Graduation Checklist</td>
                  <td>Information</td>
                  <td>
                    A checklist to aid in the Graduation Process that includes
                    step by step instructions and other important information
                    like order cap/gowns.
                  </td>
                  <td>
                    <a href="https://www.washington.edu/graduation/how-to-participate-2/">
                      Link to Graduation Checklist
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>This Form's Usage</td>
                  <td>FAQ</td>
                  <td>So what does this form actually do?</td>
                  <td>
                    This form is used to help confirm your degree details and
                    gather your prefer name that will be listed in the
                    commencement Program
                    <a href="https://www.washington.edu/ceremony/commencement-program/">
                      {" "}
                      commencement program site
                    </a>
                    .
                  </td>
                </tr>
                <tr>
                  <td>Timing</td>
                  <td>FAQ</td>
                  <td>How long can I update my name?</td>
                  <td>
                    This form will be available to update during any active
                    quarter after your degree request has been confirmed, with
                    the exception of Spring quarter. During Spring Quarter this
                    form is subject to close early to allow time for the design
                    of the Commencement Program.
                  </td>
                </tr>
              </tbody>
            </Table> */}
            <Accordion className="mt-5">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Graduation Checklist</Accordion.Header>
                <Accordion.Body>
                  <p>
                    A{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.washington.edu/graduation/how-to-participate-2/"
                    >
                      checklist
                    </a>{" "}
                    to aid in the Graduation Process that includes step by step
                    instructions and other important information like order
                    cap/gowns.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  So what does this form actually do?
                </Accordion.Header>
                <Accordion.Body>
                  This form is used to help confirm your degree details and
                  gather your prefer name that will be listed in the
                  commencement Program
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.washington.edu/ceremony/commencement-program/"
                  >
                    {" "}
                    (Commencement Program site)
                  </a>
                  .
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How long can I update my name?
                </Accordion.Header>
                <Accordion.Body>
                  This form will be available to update during any active
                  quarter after your degree request has been confirmed, with the
                  exception of Spring quarter. During Spring Quarter this form
                  is subject to close early to allow time for the design of the
                  Commencement Program.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How do I register to attend a Commencement Ceremony (either at
                  Husky Stadium or departmental?)
                </Accordion.Header>
                <Accordion.Body>
                  Please navigate to the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.washington.edu/graduation/registrationorder-form/"
                  >
                    UW Commencement's Registration / Order Form
                  </a>{" "}
                  (managed by The office of ceremonies), and follow the provided
                  instruction there.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  How do I update my name on my Diploma?
                </Accordion.Header>
                <Accordion.Body>
                  First check your email(s) from UW's Office of The University
                  Registrar for any regards to updating your Diploma name. If
                  you unable to locate that detail, please use the following{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://registrar.washington.edu/students/personal-data/names/#rec_name"
                  >
                    link
                  </a>{" "}
                  and proceed to the "How to Update Diploma Name" section.
                  Please be advise that this update is time sensitive and is
                  managed by the Office of the University Registrar.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How do I update my name on my Official Transcript, Financial
                  documents, or Immigration documents issued by the University?
                </Accordion.Header>
                <Accordion.Body>
                  To update your "Student Record Name" that appears these types
                  of official documents, you can request a name change either in
                  person at the Office of the University Registrar, 2nd Floor
                  Schmitz Hall, or via email to registra@uw.edu. Please ensure
                  you have all required materials. More information can be found{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://registrar.washington.edu/students/personal-data/names/#rec_name"
                  >
                    {" "}
                    here
                  </a>
                  .
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  How do I update my name on my, Class Roster or Husky Card
                </Accordion.Header>
                <Accordion.Body>
                  To update your "Preferred name" that appears in these Ares,
                  Log into identity.uw.edu [UWNetID required] to make changes.
                  For more information on "Preferred names" can be found{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://registrar.washington.edu/students/personal-data/names/preferred-names-faqs/"
                  >
                    {" "}
                    here
                  </a>
                  .
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  How do I update my name on the Dean's list?
                </Accordion.Header>
                <Accordion.Body>
                  To update your name on the Dean's list, please follow the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://registrar.washington.edu/students/personal-data/names/#rec_name"
                  >
                    link
                  </a>{" "}
                  to the "Dean's List Name" section for further instructions.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdditionalInfoSection;
