import { useMsal } from "@azure/msal-react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "./header";
import "./login.css";

export const LoginPage = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <>
      <Header />
      <Container className="main-container">
        <Row>
          <Row>
            <Col className="mt-1">
              <p className="pl-5 LogInTxt" id="loginDescription">
                Log in to edit your commencement name or opt-out of appearing on
                the commencement bulliten by 11:59 PM PDT on May 1st. This
                deadline is firm and cannot be extended.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 8, offset: 4 }} md={{ span: 6, offset: 4 }}>
              <Button
                className="mt-3 login-btn"
                aria-label="Log in"
                aria-describedby="loginDescription"
                variant="outline-primary"
                type="submit"
                size="lg"
                onClick={initializeSignIn}
              >
                Log in
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};
